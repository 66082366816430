export const contentRussianPhrases = [
    { phrase: ["Доброе утро!"], trans: ["Good morning!"] },
    { phrase: ["Добрый день!"], trans: ["Good afternoon!"] },
    { phrase: ["Добрый вечер!"], trans: ["Good evening!"] },
    { phrase: ["Здравствуйте!"], trans: ["Hello!"] },
    { phrase: ["Привет!"], trans: ["Hi!"] },
    { phrase: ["Как дела?"], trans: ["How are you?"] },
    { phrase: ["Хорошо! Спасибо!"], trans: ["I’m fine! Thank you!"] },
    { phrase: ["Пожалуйста!"], trans: ["Please / You are welcome!"] },
    { phrase: ["Пожалуйста!"], trans: ["You are welcome!"] },
    { phrase: ["Не за что!"], trans: ["My pleasure!"] },
    { phrase: ["До свидания!"], trans: ["Goodbye!"] },
    { phrase: ["Пока!"], trans: ["Bye!"] },
    { phrase: ["Увидимся!"], trans: ["See you later!"] },
    { phrase: ["Хорошего дня!"], trans: ["Have a good day!"] },
    { phrase: ["Простите / извините!"], trans: ["Sorry!"] },
    { phrase: ["Ничего страшного!"], trans: ["It’s OK!"] },
    { phrase: ["Как вас зовут?"], trans: ["What is your name?"] },
    { phrase: ["Приятно познакомиться."], trans: ["Nice to meet you."] },
    { phrase: ["Меня зовут"], trans: ["My name is"] },
    { phrase: ["Откуда вы?"], trans: ["Where are you from?"] },
    { phrase: ["Вы говорите по?"], trans: ["Do you speak?"] },
    { phrase: ["Говорите медленнее, пожалуйста!"], trans: ["Speak slower please!"] },
    { phrase: ["Не понимаю. Повторите, пожалуйста!"], trans: ["I do not understand. Please repeat!"] },
    { phrase: ["Пожалуйста, напишите это!"], trans: ["Please write this!"] },
    { phrase: ["Больше всего"], trans: ["Most of all"] },
    { phrase: ["И так далее..."], trans: ["And so forth..."] },
    { phrase: ["Мне стало плохо"], trans: ["I felt ill"] },
    
];