export const contentRussianNouns = [
    { phrase: ["человек"], trans: ["person"] },
    { phrase: ["дом"], trans: ["house"] },
    { phrase: ["книга"], trans: ["book"] },
    { phrase: ["стол"], trans: ["table"] },
    { phrase: ["стул"], trans: ["chair"] },
    { phrase: ["автомобиль"], trans: ["car"] },
    { phrase: ["город"], trans: ["city"] },
    { phrase: ["море"], trans: ["sea"] },
    { phrase: ["солнце"], trans: ["sun"] },
    { phrase: ["луна"], trans: ["moon"] },
    { phrase: ["дерево"], trans: ["tree"] },
    { phrase: ["собака"], trans: ["dog"] },
    { phrase: ["кот"], trans: ["cat"] },
    { phrase: ["магазин"], trans: ["store"] },
    { phrase: ["школа"], trans: ["school"] },
    { phrase: ["учитель"], trans: ["teacher"] },
    { phrase: ["студент"], trans: ["student"] },
    { phrase: ["письмо"], trans: ["letter"] },
    { phrase: ["парк"], trans: ["park"] },
    { phrase: ["река"], trans: ["river"] },
    { phrase: ["заявление"], trans: ["job application"]},
    { phrase: ["финансовую помощь"], trans: ["financial aid"]},
    { phrase: ["устроиство"], trans: ["device"]},
];