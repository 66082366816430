export const contentRussianVerbs = [
    { phrase: ["бежать"], trans: ["to run"] },
    { phrase: ["думать"], trans: ["to think"] },
    { phrase: ["есть"], trans: ["to eat"] },
    { phrase: ["пить"], trans: ["to drink"] },
    { phrase: ["говорить"], trans: ["to speak"] },
    { phrase: ["работать"], trans: ["to work"] },
    { phrase: ["читать"], trans: ["to read"] },
    { phrase: ["писать"], trans: ["to write"] },
    { phrase: ["смотреть"], trans: ["to watch"] },
    { phrase: ["слушать"], trans: ["to listen"] },
    { phrase: ["идти"], trans: ["to go (on foot)"] },
    { phrase: ["ехать"], trans: ["to go (by vehicle)"] },
    { phrase: ["плавать"], trans: ["to swim"] },
    { phrase: ["спать"], trans: ["to sleep"] },
    { phrase: ["танцевать"], trans: ["to dance"] },
    { phrase: ["покупать"], trans: ["to buy"] },
    { phrase: ["продавать"], trans: ["to sell"] },
    { phrase: ["учить"], trans: ["to teach"] },
    { phrase: ["учиться"], trans: ["to learn"] },
    { phrase: ["пойти"], trans: ["(will) walk"] },
    { phrase: ["прийти"], trans: ["to аrrive"] },
    { phrase: ["зайти"], trans: ["to enter"] },
    { phrase: ["войти"], trans: ["to go in"] },
    { phrase: ["выйти"], trans: ["to go out"] },
    { phrase: ["уйти"], trans: ["to leave"] },
    { phrase: ["подойти"], trans: ["to approach"] },
    { phrase: ["пройти"], trans: ["to pass"] },
    { phrase: ["перейти"], trans: ["to get over"] },
    { phrase: ["найти"], trans: ["to find"] },
    { phrase: ["походить"], trans: ["to resemble, to walk for a while"] },
    { phrase: ["приходить"], trans: ["to аrrive"] },
    { phrase: ["заходить"], trans: ["to enter"] },
    { phrase: ["входить"], trans: ["to go in"] },
    { phrase: ["выходить"], trans: ["to go out"] },
    { phrase: ["уходить"], trans: ["to leave"] },
    { phrase: ["подходить"], trans: ["to fit, to match"] },
    { phrase: ["проходить"], trans: ["to pass"] },
    { phrase: ["переходить"], trans: ["to get over"] },
    { phrase: ["находить"], trans: ["to find"] },
    { phrase: ["поехать"], trans: ["to go somewhere (in a vehicle)"] },
    { phrase: ["приехать"], trans: ["to аrrive (via vehicle)"] },
    { phrase: ["заехать"], trans: ["to pick up"] },
    { phrase: ["въехать"], trans: ["to go in"] },
    { phrase: ["выехать"], trans: ["to go out"] },
    { phrase: ["уехать"], trans: ["to leave"] },
    { phrase: ["подъехать"], trans: ["to approach"] },
    { phrase: ["проехать"], trans: ["to pass"] },
    { phrase: ["переехать"], trans: ["to run over, to move somewhere"] },
    { phrase: ["приезжать"], trans: ["to arrive"] },
    { phrase: ["заезжать"], trans: ["to pick up"] },
    { phrase: ["въезжать"], trans: ["to enter"] },
    { phrase: ["уезжать"], trans: ["to leave"] },
    { phrase: ["подъезжать"], trans: ["to arrive"] },
    { phrase: ["проезжать"], trans: ["to drive through"] },
    { phrase: ["переезжать"], trans: ["to cross, to move"] },
    { phrase: ["Функцировать"], trans: ["to function"] },
];